@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-primary {
    @apply px-8 py-3 bg-orange-500 text-white rounded-full shadow-lg hover:bg-orange-600 hover:scale-105 transform transition-all duration-300 ease-in-out;
}



.animate-fadeIn {
    @apply opacity-0;
    animation: fadeIn 2s forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
